export default {
    methods: {
        goChina(data) {
            const { href } = this.$router.resolve({
                path: data.def10 ? '/store/dr_material_drugs' : '/store/dr_domestic_drugs',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                    CompName: this.baseQuery ? this.baseQuery.compName : undefined,
                }
            });
            window.open(href, "_blank");
        },
        goForeign(data) {
            const { href } = this.$router.resolve({
                path: data.def10 ? '/store/dr_material_drugs' : '/store/dr_imported_drugs',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                    compName: this.baseQuery ? this.baseQuery.compName : undefined,
                }
            });
            window.open(href, "_blank");
        },
        goMaterial(data) {
            const { href } = this.$router.resolve({
                path: '/store/dr_material_drugs',
                query: {
                    comName: data.comName,
                    compName: this.baseQuery ? this.baseQuery.compName : undefined,
                }
            });
            window.open(href, "_blank");
        },
        goRegister(data) {
            const { href } = this.$router.resolve({
                path: '/store/flow_development_review_progress',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                }
            });
            window.open(href, "_blank");
        },
        goHealth(data) {
            const { href } = this.$router.resolve({
                path: '/store/du_health_insurance_directories',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                }
            });
            window.open(href, "_blank");
        },
        goBase(data) {
            const { href } = this.$router.resolve({
                path: '/store/du_essential_drugs',
                query: {
                    comName: data.comName,
                    comCode: data.comCode,
                }
            });
            window.open(href, "_blank");
        },
        goBid(data) {
            let query = {}
            let arr = [
                { name: 'compCode', op: 'eq', value: data.compCode, },
                { name: 'comCode', op: 'eq', value: data.comCode, },
                { name: 'unifySpecCode', op: 'eq', value: data.unifySpecCode, },
                { name: 'conversionRatio', op: 'eq', value: data.conversionRatio, },
                { name: 'targetPerformanceTypeName', op: 'notIn', value: [ '不执行', '终止', '待执行', '未中选'], },
            ]
            query.isParam = true
            query.str = 'search-searchMixin'
            arr = JSON.stringify(arr)
            this.$help.store.set('search-searchMixin', arr)
            const { href } = this.$router.resolve({
                path: '/store/mainf_drug_winning_informations',
                query,
            });
            window.open(href, "_blank");
        },
        goDrugTotal(data) {
            const { href } = this.$router.resolve({
                path: `/new/aim_drugs_drug`,
                query: {
                    comCode: data.comCode
                }
            });
            window.open(href, "_blank");
        },
        goNation(data) {
            const { href } = this.$router.resolve({
                path: `/store/dr_national_centralized_procurement`,
                query: {
                    comName: data.comName,
                    compName: data.compName,
                }
            });
            window.open(href, "_blank");
        },
        goNationProcurement(data) {
            const { href } = this.$router.resolve({
                path: `/store/dr_province_centralized_procurement`,
                query: {
                    comName: data.comName,
                    compName: data.compName,
                    goNationProcurement: data.goNationProcurement,
                    conversionRatio: data.conversionRatio,
                    buyState: '正在执行',
                }
            });
            window.open(href, "_blank");
        },
    }
}